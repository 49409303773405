<template>
	<main class="page-container" id="reservation-home">
		<div class="page-header is-sticky">
			<div class="page-component">
				<div class="display-flex is-between">
					<div class="fl">
						<h2 class="page-header-title">소담스퀘어</h2>
					</div>
					<div class="fr">
						<button type="button" @click="goMyRsvt()" class="button-default is-small is-primary is-rounded">내 예약</button>
					</div>
				</div>
				<p class="page-header-desc">소상공인이면 누구나 사진촬영, 영상제작, 라이브방송 등 온라인 시장진출을 위한 콘텐츠를 제작할 수 있습니다.</p>
			</div>
		</div>
		<div class="page-component">
			<nav class="page-nav is-rounded">
				<ul class="nav-list">
					<li class="nav-item" :class="{ 'is-selected': activeNav === item.dcd }" v-for="(item, idx) in filteredNavList" :key="item.dcd">
						<button type="button" class="nav-text" @click.prevent="clickNav(item.dcd)">{{ item.dcdVal }}</button>
					</li>
				</ul>
			</nav>
			<div class="page-nav-desc">
				<p v-if="activeNav === '1402001'">전국 거점 별 소상공인 전용 스튜디오 및 장비 예약이 가능합니다.</p>
				<p v-else-if="activeNav === '1402002'">촬영, 라이브 방송 등 전문화된 장비 대여를 통해 소상공인 콘텐츠 제작을 지원합니다.</p>
				<p v-else-if="activeNav === '1499998'">소상공인 전용 O2O 플래그십 스토어로, 사업 신청 및 모집을 통해 O2O 판로확대를 지원합니다.</p>
				<p else></p>
			</div>
			<div class="local-map-wrapper">
				<div class="flex-item flex-l">
					<div class="local-map-content">
						<map-svg svgWidth="355" svgHeight="626" svgBorder="#BDBDBD" />
						<div class="local-map-list">
							<button v-for="(item, idx) in filteredLocalMapList" :key="idx" type="button" class="local-map-itemr" :class="[`local-map-itemr__${item.grpArEnNm}`, { 'is-active': activeMapId == item.grpArDcd }]" @click.prevent="clickMapItem(item.grpArDcd)">
								<span class="local-map-numsr">{{ item.spaceList.length }}</span>
							</button>
						</div>
					</div>
				</div>
				<div class="flex-item flex-r">
					<section class="local-map-section">
						<h3 class="sr-only">지역별 공간 리스트</h3>
						<ul class="reserv-list">
							<li v-for="(item, idx) in filteredSpaceList" :key="idx">
								<template v-if="activeNav == '1499998' && session.userId != 'undefined'">
									<div class="reserv-item">
										<!-- 소담상회 -->
										<div class="fl">
											<span class="reserv-item-thumb">
												<img :src="require(`@/assets/img/sodam/${item.rprsImgUrl}`)" :alt="item.spaceNm" @error="handleImgError" />
											</span>
										</div>
										<div class="fr">
											<div class="reserv-item-text">
												<strong class="reserv-item-text__title">{{ item.spaceNm }}</strong>
												<span class="reserv-item-text__desc">{{ item.addr }}</span>
												<span class="reserv-item-text__desc mobile-hidden">{{ item.addrDtl }}</span>
											</div>
											<div class="buttons buttons-flex">
												<a :href="item.guideCn" class="button-default is-small is-outlined is-rounded" target="_blank" title="새창열림">안내</a>
												<a :href="item.homePageUrl" class="button-default is-small is-primary is-rounded" target="_blank" title="새창열림">신청</a>
											</div>
										</div>
									</div>
								</template>
								<template v-else>
									<router-link :to="`space/${item.spaceId}`" class="reserv-item">
										<div class="fl">
											<span class="reserv-item-thumb">
												<img :src="item.rprsImgUrl" :alt="item.spaceNm" @error="handleImgError" />
											</span>
										</div>
										<div class="fr">
											<div class="reserv-item-text">
												<strong class="reserv-item-text__title">{{ item.spaceNm }}</strong>
												<span class="reserv-item-text__desc mobile-hidden">{{ getOpenDtDesc(item) }}</span>
												<span class="reserv-item-text__desc mobile-hidden">{{ getAddrDesc(item) }}</span>
											</div>
											<div class="buttons buttons-flex" @click.stop>
												<a :href="`tel:${item.telNo}`" class="button-default is-small is-outlined is-rounded mobile-show">전화</a>
												<button type="button" class="button-default is-small is-outlined is-rounded" @click.prevent="goInq(item.spaceId)">문의</button>
												<router-link :to="`/space/${item.spaceId}?tab=reserv`" class="button-default is-small is-primary is-rounded"> 예약 </router-link>
											</div>
										</div>
									</router-link>
								</template>
							</li>
						</ul>
					</section>
				</div>
			</div>
			<section class="reserv-program">
				<div class="reserv-program-header">
					<h3 class="reserv-program-header__title">프로그램</h3>
				</div>
				<div class="reserv-program-content">
					<swiper class="swiper" ref="swipeResrv" :options="swiperOptionReserv">
						<swiper-slide v-for="(sprtBiz, idx2) in sprtBizList" :key="idx2">
							<article class="sptpgm-article">
								<header class="sptpgm-article-header">
									<div class="fl">
										<span class="sptpgm-article-header__tag is-category">{{ sprtBiz.sprtBizTyNm }}</span>
										<span class="sptpgm-article-header__tag">{{ sprtBiz.sprtBizTrgtNm }}</span>
										<span class="sptpgm-article-header__tag">{{ sprtBiz.sprtBizCtpvNm }}</span>
									</div>
									<div class="fr">
										<span class="sptpgm-article-header__dday">D-{{ sprtBiz.dday }}</span>
									</div>
								</header>
								<div class="sptpgm-article-body">
									<h3 class="sptpgm-article-title">{{ sprtBiz.sprtBizNm }}</h3>
								</div>
								<footer class="sptpgm-article-footer display-flex">
									<dl class="sptpgm-article-dl">
										<dt><span class="sr-only">기간</span></dt>
										<dd>{{ sprtBiz.rcritBgngYmd }} ~ {{ sprtBiz.rcritEndYmd }}</dd>
									</dl>
									<div class="buttons buttons-flex">
										<template v-if="isOpen(sprtBiz.sprtBizEndYmd)">
											<a :href="`https://fanfandaero.kr/portal/preSprtBizPbancDetail.do?sprtBizCd=${sprtBiz.sprtBizCd}`" target="_blank" class="button-default is-primary is-rounded">신청</a>
										</template>
										<template v-else>
											<span class="button-default is-primary is-medium is-rounded is-disabled">마감</span>
										</template>
									</div>
								</footer>
							</article>
						</swiper-slide>
					</swiper>
					<div class="swiper-buttons-container swiper-buttons-container__sround">
						<div class="swiper-button-prev" slot="button-prev"></div>
						<div class="swiper-button-next" slot="button-next"></div>
					</div>
				</div>
			</section>
		</div>
	</main>
</template>

<script>
import { mapGetters } from 'vuex';
import { ACT_GET_MSS_SPRT_BIZ_SEARCH_LIST, ACT_GET_SPACE_GRP_LIST, ACT_GET_COMMON_CODE_LIST, ACT_GET_SUBS_ECNMY_DTL_LIST } from '@/store/_act_consts';
import { MUT_SET_RETURN_ROUTE, MUT_SHOW_CONFIRM } from '@/store/_mut_consts';

import { getItems, lengthCheck, getCheckItems, hhmmToDateFormat, dateToDateFormat } from '@/assets/js/utils';
import MapSvg from '@/components/common/MapSvg.vue';
import errorImg from '@/assets/img/common/error.png';

export default {
	name: 'SpaceHome',
	components: { MapSvg },
	watch: {},
	computed: {
		...mapGetters('common', ['isMobile', 'commonCode']),
		...mapGetters('auth', ['session', 'isAuth']),
		filteredLocalMapList() {
			return this.localMapList.filter((item) => item.grpTypDcd == this.activeNav);
		},
		filteredSpaceList() {
			if (this.activeMapId) return this.localMapList.find((item) => item.grpTypDcd == this.activeNav && item.grpArDcd == this.activeMapId)?.spaceList;
			else {
				const arList = this.localMapList.filter((item) => item.grpTypDcd == this.activeNav);
				let spaceList = [];
				arList.forEach((e) => {
					spaceList = spaceList.concat(e?.spaceList);
				});
				return spaceList;
			}
		},
		filteredNavList() {
			const favrList = this.localMapList.find((m) => m.grpTypDcd === '1499999');
			return this.isAuth && favrList && favrList.spaceList?.length > 0 ? this.navList : this.navList.filter((nav) => nav.dcd != '1499999');
		},
	},
	data: () => ({
		activeNav: '-1',
		navList: [],
		activeMapId: '', // 활성화된 지역 id
		localMapList: [],
		//		spaceList: [],
		sprtBizList: [],
		swiperOptionReserv: {
			loop: false,
			slidesPerView: 3,
			spaceBetween: 24,
			navigation: {
				nextEl: '.reserv-program-content .swiper-button-next',
				prevEl: '.reserv-program-content .swiper-button-prev',
			},
			breakpoints: {
				600: {
					slidesPerView: 2,
					spaceBetween: 15,
				},
				1000: {
					slidesPerView: 3,
					spaceBetween: 24,
				},
			},
		},
	}),
	created() {
		if (this.navList.length === 0) this.getCommonCdList();
		this.getSprtBizList();
	},
	mounted() {
		this.$nextTick(() => {});
	},
	methods: {
		async getCommonCdList() {
			await this.$store.dispatch(`common/${ACT_GET_COMMON_CODE_LIST}`, { dbColumn: 'SPACE_TYPE_DCD', masterYn: 'N' }).then((res) => {
				/*
					순서 : 소담스퀘어(DB조회) > 소담상회 > 즐겨찾기
				*/
				this.navList = this.navList.concat(getCheckItems(res));
				// 소담상회
				this.navList = this.navList.concat({
					dcd: '1499998',
					dcdVal: '소담상회',
					masterYn: 'N',
					displayOrd: 149998,
					dbColumn: 'SPACE_TYPE_DCD',
					dbColumnNm: null,
					partialCd: null,
					addInfo: null,
				});

				//즐겨찾기 탭 추가
				this.navList = this.navList.concat({
					dcd: '1499999',
					dcdVal: '즐겨찾기',
					masterYn: 'N',
					displayOrd: 149999,
					dbColumn: 'SPACE_TYPE_DCD',
					dbColumnNm: null,
					partialCd: null,
					addInfo: null,
				});
			});

			await this.getSpaceList();
		},

		getSprtBizList() {
			// api 호출
			this.$store
				.dispatch(`support/${ACT_GET_MSS_SPRT_BIZ_SEARCH_LIST}`, {
					sprtBizCg2Nm: '온라인 기반구축',
					sprtBizCg3Nm: '소담스퀘어',
					pageNo: 1,
					pageSize: 9,
					//sprtBizCtpvNm: this.mssArea === '전국' ? '' : this.mssArea,
					//sprtBizCtpvNm: '',
					//sprtBizTyNm: '',
				})
				.then((res) => {
					this.sprtBizList = getCheckItems(res);
				})
				.catch((e) => {
					console.error(e);
				});
		},

		async getSpaceList() {
			this.$store.dispatch(`space/${ACT_GET_SPACE_GRP_LIST}`, {}).then((res) => {
				this.localMapList = getCheckItems(res);
				const favr = this.localMapList.find((m) => m.grpTypDcd === '1499999');
				if (favr && favr.spaceList && favr.spaceList.length > 0) {
					this.activeNav = favr.grpTypDcd;
					// this.clickNav(favr.grpTypDcd);
				} else {
					 this.clickNav(this.navList[0]?.dcd);
				}
			});
		},

		chkLogin() {
			if (typeof this.session.userId == 'undefined') {
				const param = {
					title: '회원가입 및 로그인이 필요합니다.',
					html: true,
					showCancelButton: false,
					yesfunc: () => {
						this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path);
						this.$router.push({ name: 'Login' });
					},
					nofunc: () => {},
				};
				this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, param);
				return false;
			}

			return true;
		},

		goMyRsvt() {
			if (this.chkLogin()) this.$router.push({ name: 'SpaceMyRsvt' });
		},

		goInq(spaceId) {
			if (!this.chkLogin()) return false;
			this.$router.push({ name: 'SpaceRsvtInquiryInput', params: { spaceId } });
		},

		getOpenDtDesc(item) {
			// 10:00 ~ 20:00 / 12~13시 예약불가 / 주말, 공휴일 휴무
			let oper = hhmmToDateFormat(item.operStrtHhmm, ':') + ' ~ ' + hhmmToDateFormat(item.operEndHhmm, ':');

			// 예약불가
			let impsbTimes = '';
			if (item.spaceImpsbTimes && item.spaceImpsbTimes.length > 0) {
				impsbTimes = ' / ';
				item.spaceImpsbTimes.split(',').forEach((d) => {
					if (impsbTimes != ' / ') impsbTimes += ', ';
					d.split('|').forEach((e, i) => {
						impsbTimes += hhmmToDateFormat(e, ':') + (i == 0 ? ' ~ ' : '');
					});
				});

				impsbTimes += ' 예약불가';
			}

			//주말, 공휴일 휴무
			let holdyOrWekend = '';
			if (item.holdyOperYn == 'N' || item.wekendOperYn == 'N') {
				holdyOrWekend = ' / ';
				if (item.holdyOperYn == 'N') {
					holdyOrWekend += '주말';
				}
				if (item.wekendOperYn == 'N') {
					holdyOrWekend += item.holdyOperYn == 'N' ? ', 공휴일' : '공휴일';
				}
				holdyOrWekend += ' 휴무';
			}

			return `${oper}${impsbTimes}${holdyOrWekend}`;
		},

		getAddrDesc(item) {
			return `${item.addr} ${item.addrDtl}`;
		},

		isOpen(endYmd) {
			let endDate = this.toDate(endYmd);
			return endDate >= this.toDate(dateToDateFormat(new Date(), 'yyyy-MM-dd'));
		},

		toDate(date_str) {
			var yyyyMMdd = String(date_str);
			var sYear = yyyyMMdd.substring(0, 4);
			var sMonth = yyyyMMdd.substring(5, 7);
			var sDate = yyyyMMdd.substring(8, 10);

			return new Date(Number(sYear), Number(sMonth) - 1, Number(sDate));
		},

		// custom fn ========================================================
		handleImgError(e) {
			e.target.src = errorImg;
		},
		clickNav(idx) {
			this.activeNav = idx;
			this.activeMapId = '';
			// if(idx != "1499998") {
			// 	this.activeMapId = '';
			// } else {
			// 	this.activeMapId = this.filteredLocalMapList[0]?.grpArDcd;
			// }
		},

		// 지역 맵 별 활성화
		clickMapItem(i) {
			this.activeMapId = i;
		},
		// 지역별 더보기 클릭
		clickLocalMore(i) {
			this.visLocalMore = this.visLocalMore === i ? -1 : i;
		},
	},
};
</script>
